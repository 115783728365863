@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lexend Deca", sans-serif;
  font-weight: theme(fontWeight.light);
  height: 100%;
}

html {
  height: 100%;
}

#__next {
  height: 100%;
}

@layer components {
  /* when you animate border width, it bounces out the content around it, using box shadow to get around it */
  .input-wrapper {
    box-shadow: 0 0 0 theme(spacing.2) theme(colors.grey.15);
    transition: box-shadow 0.15s ease-in-out;
  }

  .input-wrapper:hover {
    box-shadow: 0 0 0 theme(spacing.4) theme(colors.grey.15);
  }

  .input-wrapper:focus-within {
    box-shadow: 0 0 0 theme(spacing.4) theme(colors.blue.100);
  }

  .input-wrapper.invalid {
    box-shadow: 0 0 0 theme(spacing.4) theme(colors.red.100);
  }

  .checkbox:focus-visible + label {
    box-shadow: 0 0 0 theme(spacing.4) theme(colors.blue.100);
  }
}

@layer utilities {
  .dark-gradient {
    background: radial-gradient(
      138.96% 140% at 121.87% 126.46%,
      #272d7c 34.37%,
      #314284 52.21%,
      #3e5c8e 64.15%,
      #5a94a4 75.53%,
      #86efc7 87.39%
    );
  }

  .light-gradient {
    background: radial-gradient(
      138.96% 140% at 121.87% 126.46%,
      rgba(217, 239, 153, 0.99) 0%,
      #86efc7 63.33%
    );
  }
}

@layer utilities {
  .text-shadow-bold {
    text-shadow: 0px 0px 1px black;
  }
  .text-shadow-bold:hover {
    text-shadow: none;
  }
  .tabmaskshadow::before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 56%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .tabmaskshadow::after {
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 56%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
